.idNr {
	color: rgba(0, 0, 0, 0.5);
	transition: transform 0.225s;
}
.rounded-20 {
	border-radius: 20px;
}

.card-name {
	color: white;
	font-size: 44px;
	position: absolute;
	width: 320px;
	left: 35px;
	bottom: 15px;
}

.pokemon-id {
	position: relative;
	color: rgba(255, 255, 255, 0.39);
	font-size: 90px;
}

.capitalize {
	text-transform: capitalize;
}

.modal-poke-img {
	width: 70%;
	margin-bottom: 5rem;
}

.pokemonCards {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	width: 300px;
	height: 150px;
	background-attachment: fixed;
	border-radius: 20px;
	padding: 25px;
	margin: 20px;
	cursor: pointer;
	transition: all 225ms ease-in-out;
}

.pokemonCards:hover {
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	transform: translateY(-2px);
}

.card-img {
	width: 175px;
}
.card-img-icon {
	width: 24px;
	margin-right: 5px;
}

.cardModal-img-icon {
	position: absolute;
	left: 0;
	width: 25%;
}

.type-width{
width: 100px;
    padding-left: 15px;
}

.fullScreen {
	z-index: 8888;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #fff;
}

.progress {
	height: 0.5rem;
}

.border-vertical {
	border-left: 1px solid #808080;
	width: 3rem;
}

.base-stats-name {
	text-align: end;
	width: 6rem;
}
